<template>
  <div class="container certificate">
    <div class="container-fluid">
      <div class="flex conet j-w">
        <div class="certificate-text">辐射教师培训成教师培训成果 | 证书</div>
        <div class="certificate-btn flex conet j-c">下载</div>
      </div>
      <div class="bg"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Certificate'
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.certificate {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  .certificate-text {
    .fontSize(@TEXT-SIZE-14);
  }
  .certificate-btn {
    width: 80px;
    height: 30px;
    border-radius: 20px;
    background: @TEXT-BG-00BD92;
    color: @TEXT-COLOR-FFFFFF;
    .fontSize(@TEXT-SIZE-16);
    cursor: pointer;
  }
  .bg {
    width: 1180px;
    height: 664px;
    margin-top: 15px;
    background: rgba(230, 230, 230, 1);
    opacity: 0.76;
    margin-bottom: 20px;
  }
}
</style>